import React from "react"
import { ContactBox } from "../components/styled"
const Contact = () => {
  return (
    <ContactBox>
      <p>
        <span>メキシコ求人情報とメキシコ就職・転職サポート</span>
        <a href="tel: +52-449-153-1400" target="_blank" rel="noreferrer">
          TEL +52-449-153-1400
        </a>
      </p>
      <a
        className="btn-contact"
        href="/contact-us/"
        target="_blank"
        rel="noreferrer"
      >
        その他お問い合わせはこちらへ
      </a>
    </ContactBox>
  )
}

export default Contact
