import React, {useState, useEffect }  from "react"
import { Link } from "gatsby"
// Components
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"

const ThankYou = () => {
  const [key, setKey] = useState(undefined)

  useEffect(() => {
    setKey(localStorage.getItem('thankload'))
  }, [])
  if (key === "1"){
    window.location.reload(true);
    localStorage.setItem('thankload', '0');
  }
  return (
    <>
      <Seo
        title={`お問い合わせありがとうございました。 | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />

      <section className="container about">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="about__company-profile">
              <SectionTitle>お問い合わせありがとうございました。</SectionTitle>
              <hr className="u-line-bottom" />
            </div>
            <div className="container-full u-margin-bottom-2">
              <div className="row no-gutters">
                <p className="u-size-1 u-margin-bottom-1 u-margin-top-1 u-line-height">
                  この度は、クイックグローバルメキシコの求人へお問い合わせ・お申し込み頂きありがとうございます。
                  自動送信のメールにてご連絡させていただきましたので、ご確認ください。
                </p>
                <p className="u-size-0-8 u-margin-bottom-1 u-margin-top-1 u-line-height">
                  ※もし数分経っても届かない場合は、お手数ですが直接メールをいただけますようお願い致します。迷惑メールフォルダに入ってしまう場合もありますので、その際はそちらもご確認ください。
                  メールアドレス：
                  <a
                    className="u-color-danger"
                    href="maito:quick@919mexico.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    quick@919mexico.com
                  </a>
                </p>
                <p className="u-margin-bottom-1 u-margin-top-1 u-line-height">
                  また、
                  <Link className="u-color-danger" to="/process">
                    お申し込みの流れ（サポートの流れ）
                  </Link>
                  から今後の流れをご確認いただけます。
                </p>
                <p className="u-margin-bottom-1 u-margin-top-1 u-line-height">
                  QUICK GLOBAL MEXICO, S.A. DE C.V. <br />
                  住所：C.C. Punto 45, Boulevard A Zacatecas 845, <br />
                  Oficina 510, Desarrollo Especial Bulevar a Zacatecas,
                  <br />
                  C.P.20116 Aguascalientes, Ags.
                  <br />
                  電話番号：+52-449-153-1400
                  <br />
                  Eメール：quick@919mexico.com
                  <br />
                  営業時間：平日9：00～18：00（メキシコ中部時間）
                  <br />
                </p>
              </div>
            </div>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default ThankYou
